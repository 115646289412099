import EmailSignIn from 'firebase/EmailSignIn'
import { Stack, Heading, Flex, Alert, AlertIcon } from '@chakra-ui/core'
import { useRouter } from 'next/router'

export const LoginForm = ({
  title = 'Login or Sign up',
  errorMessage,
}: {
  title?: string
  errorMessage?: string
}) => {
  return (
    <Stack spacing={4} minW="350px">
      <Heading size="lg" color="white">
        {title}
      </Heading>
      <EmailSignIn redirectTo={`${process.env.NEXT_PUBLIC_STEREO}/dashboard`} />
      {errorMessage && (
        <Alert color="white" mt={4} status="error">
          <AlertIcon />
          {errorMessage}
        </Alert>
      )}
    </Stack>
  )
}

const Login = () => {
  const router = useRouter()
  const { query } = router

  return (
    <Flex w="100%" h="100vh" justifyContent="center" alignItems="center">
      <LoginForm
        title="Login"
        errorMessage={
          query.error === 'expired'
            ? 'This login link has expired. Request a new link to login.'
            : undefined
        }
      />
    </Flex>
  )
}

export default Login

import { useField, Formik, Form } from 'formik'
import {
  FormControl,
  Input,
  FormErrorMessage,
  Alert,
  AlertIcon,
  ScaleFade,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from '@chakra-ui/core'
import * as Yup from 'yup'
import { FormikSubmit } from 'ava/chakra/formik'
import FirebaseClient from './FirebaseClient'
import { actionCodeSettings } from './config'
import { useState, useEffect } from 'react'

const Email = props => {
  const [field, meta] = useField(props)

  return (
    <FormControl mb={4} isRequired isInvalid={meta.touched && !!meta.error}>
      <Input
        data-analytics="email-login-input"
        color="white"
        placeholder="Email Address"
        {...props}
        {...field}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}

export default function EmailSignIn({
  redirectTo = typeof window !== 'undefined' ? window.location.href : null,
}: {
  redirectTo?: string
}) {
  const [success, setSuccess] = useState(false)
  const [isEmailSignInProcessing, setIsEmailSignInProcessing] = useState(false)

  useEffect(() => {
    if (FirebaseClient.auth().isSignInWithEmailLink(window.location.href)) {
      setIsEmailSignInProcessing(true)
    }
  }, [])

  if (isEmailSignInProcessing) {
    return <Spinner alignSelf="center" justifySelf="center" />
  }

  const initialValues = { email: '' }
  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
  })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async ({ email: _email }, { setSubmitting }) => {
        const email = _email.trim()
        await FirebaseClient.auth().sendSignInLinkToEmail(email, {
          ...actionCodeSettings,
          url: `${redirectTo}?email=${encodeURIComponent(email)}`,
        })
        window.localStorage.setItem('emailForSignIn', email)
        setSubmitting(false)
        setSuccess(true)
      }}
    >
      <Form>
        <Email name="email" />
        <FormikSubmit data-analytics="email-login-submit">
          Email me a login link
        </FormikSubmit>
        {success && (
          <Alert color="white" mt={4} status="success">
            <AlertIcon />
            Login link emailed! Check your email and click the link to sign in.
          </Alert>
        )}
      </Form>
    </Formik>
  )
}

export const EmailSignInModal = ({
  header,
  isOpen,
  onClose,
  isClosable = true,
  useInert = true,
}: {
  header: string
  isOpen: boolean
  onClose: () => void
  isClosable?: boolean
  useInert?: boolean
}) => (
  <ScaleFade in={isOpen}>
    {styles => (
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={isClosable}
        closeOnEsc={isClosable}
        useInert={useInert}
      >
        <ModalOverlay opacity={styles.opacity} />
        <ModalContent {...styles}>
          <ModalHeader color="white">{header}</ModalHeader>
          <ModalBody alignItems="center" justifyContent="center">
            <EmailSignIn />
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    )}
  </ScaleFade>
)

import { useFormikContext } from 'formik'
import { Button } from '@chakra-ui/core'

export const FormikSubmit = ({ children, ...rest }) => {
  const { isSubmitting, isValidating } = useFormikContext()

  return (
    <Button
      marginTop={2}
      colorScheme="yellow"
      isFullWidth
      isLoading={isSubmitting || isValidating}
      type="submit"
      {...rest}
    >
      {children}
    </Button>
  )
}
